.hint-pulse:focus {
  border: 0;
  outline: 0;
}

.hint-pulse:hover > .hint-pulse-dot {
  border: 5px solid rgba(180, 159, 67, 0.57);
}

.hint-pulse-no-anim .hint-pulse-pulse {
  -webkit-animation: none;
  -moz-animation: none;
  animation: none;
}

.hidden {
  display: none;
}

.hint-pulse {
  position: absolute;
  box-sizing: content-box;
  background: transparent;
  cursor: pointer;
}

.hint-pulse .hint-pulse-fixedhint {
  position: fixed;
}

.hint-pulse .hint-pulse-dot {
  box-sizing: content-box;
  width: 10px;
  height: 10px;
  border: 5px solid rgba(180, 159, 67, 0.18);
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background-color: rgba(240, 211, 89, 0.16);
  z-index: 10;
  position: absolute;
  top: -10px;
  left: -10px;
}

.hint-pulse .hint-pulse-pulse {
  pointer-events: none;
  box-sizing: content-box;
  border: 5px solid rgba(93, 253, 203, 1);
  background: rgba(93, 253, 203, 1);
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  border-radius: 60px;
  height: 50px;
  width: 50px;
  animation: hint-pulse-frames 900ms 0ms infinite linear;
  -webkit-animation: hint-pulse-frames 900ms 0ms infinite linear;
  -moz-animation: hint-pulse-frames 900ms 0ms infinite linear;
  -ms-animation: hint-pulse-frames 900ms 0ms infinite linear;
  -o-animation: hint-pulse-frames 900ms 0ms infinite linear;
  position: absolute;
  top: -25px;
  left: -25px;
  z-index: 1;
  opacity: 0;
}

.hint-pulse .hint-pulse-pulse::before,
.hint-pulse .hint-pulse-pulse::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(93, 253, 203, 0.5);
  border-radius: 50px;
  opacity: 0;
}

.hint-pulse .hint-pulse-pulse::before {
  transform: scale(0.33);
  animation: hint-pulse-frames 600ms 300ms infinite linear;
  -webkit-animation: hint-pulse-frames 600ms 300ms infinite linear;
  -moz-animation: hint-pulse-frames 600ms 300ms infinite linear;
  -ms-animation: hint-pulse-frames 600ms 300ms infinite linear;
  -o-animation: hint-pulse-frames 600ms 300ms infinite linear;
}

.hint-pulse .hint-pulse-pulse::after {
  transform: scale(1);
  animation: hint-pulse-frames 1200ms 600ms infinite linear;
  -webkit-animation: hint-pulse-frames 1200ms 600ms infinite linear;
  -moz-animation: hint-pulse-frames 1200ms 600ms infinite linear;
  -ms-animation: hint-pulse-frames 1200ms 600ms infinite linear;
  -o-animation: hint-pulse-frames 1200ms 600ms infinite linear;
}

.hint-pulse.top {
  top: 0;
  left: 50%;
}

.hint-pulse.top-left {
  top: 0;
  left: 0;
}

.hint-pulse.top-right {
  top: 0;
  left: 100%;
}

.hint-pulse.right {
  top: 50%;
  left: 100%;
}

.hint-pulse.left {
  top: 50%;
  left: 0;
}

.hint-pulse.bottom {
  top: 100%;
  left: 50%;
}

.hint-pulse.bottom-left {
  top: 100%;
  left: 0;
}

.hint-pulse.bottom-right {
  top: 100%;
  left: 100%;
}

.hint-pulse.center {
  top: 50%;
  left: 50%;
}

@keyframes hint-pulse-frames {
  0% {
    transform: scale(0);
    opacity: 0.5;
  }
  50% {
    transform: scale(0.75);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

@-webkit-keyframes hint-pulse-frames {
  0% {
    transform: scale(0);
    opacity: 0.5;
  }
  50% {
    transform: scale(0.75);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

@-moz-keyframes hint-pulse-frames {
  0% {
    transform: scale(0);
    opacity: 0.5;
  }
  50% {
    transform: scale(0.75);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

@-ms-keyframes hint-pulse-frames {
  0% {
    transform: scale(0);
    opacity: 0.5;
  }
  50% {
    transform: scale(0.75);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

@-o-keyframes hint-pulse-frames {
  0% {
    transform: scale(0);
    opacity: 0.5;
  }
  50% {
    transform: scale(0.75);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
