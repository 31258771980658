.open-book-list-item {
  list-style-type: '📖';
}

@counter-style book-part {
  system: cyclic;
  symbols: 📕 📗 📘 📙;
}

.book-part-list-item {
  list-style: book-part;
}
